var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"showroom-slider"},[_c('div',[_c('div',{directives:[{name:"aware",rawName:"v-aware"}],ref:"container",staticClass:"showroom-slider-container",on:{"viewport":_vm.onResize}},[_c('ul',{directives:[{name:"aware",rawName:"v-aware"}],ref:"list",staticClass:"showroom-slider__items",on:{"viewport":_vm.onResize}},_vm._l((_vm.content.pictures),function(item,i){return _c('li',{key:((_vm.isPicture(item) ? item.rawUrl : item.video) + "-" + i),ref:"item",refInFor:true,staticClass:"showroom-slider__item",class:{ 'has-video': !_vm.isPicture(item) }},[(_vm.isPicture(item))?_c('GPicture',{staticClass:"showroom-slider__item__picture",attrs:{"content":item,"sets":['160', '240', '320', '480', '640', '960', '1280']}}):(item.video)?_c('div',{staticClass:"showroom-slider__item__picture"},[_c('video',{attrs:{"muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":item.video,"type":"video/mp4"}})])]):_vm._e()],1)}),0)]),_c('div',{staticClass:"showroom-slider__controls"},[_c('span',{staticClass:"showroom-slider__label__pictures"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"symbol":"ui-photo","size":"0 0 20 20"}}),_vm._v(" "+_vm._s(_vm.content.pictures.length)+" ")]),_c('GAction',{staticClass:"showroom-slider__controls__item--prev",attrs:{"content":{
          label: 'Précédent',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'leftArrow',
        },"aria-label":"Précédent","disabled":_vm.current === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('GAction',{staticClass:"showroom-slider__controls__item--next",attrs:{"content":{
          label: 'Suivant',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'arrow',
        },"aria-label":"Suivant","disabled":_vm.current > _vm.content.pictures.length - _vm.number - 1},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }