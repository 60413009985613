





























import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'
import { FooterCta } from '@/inc/types'

export default defineComponent({
  name: 'ChromeFooterCta',
  props: {
    content: {
      type: Object as () => FooterCta,
      required: true,
    },
  },
  setup(props, ctx) {
    const openPopup = () => {
      ctx.emit('popup:open', props.content.overlay)
    }

    return {
      openPopup,
      ...useGetters(['chrome']),
    }
  },
})
