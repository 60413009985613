




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GIntro',
  props: {
    content: {
      type: String,
      default:
        // eslint-disable-next-line max-len
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Enim sed feugiat hendrerit lobortis lacus, nisi, lacus ut consectetur. Neque a vitae nunc, nullam tempor semper convallis eget tempor. Vestibulum sed et interdum adipiscing consectetur. Neque nam quis viverra adipiscing tellus porttitor ut nunc. </p>',
    },
  },
})
