

































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface Pill {
  icon?: string
  logo?: Picture
  title: string
  text: string
  url?: string
}

export default defineComponent({
  name: 'GPill',
  components: {},
  props: {
    content: {
      type: Object as PropType<Pill>,
      required: true,
    },
    tag: {
      type: String,
      required: false,
    },
    arrow: {
      type: [String, Boolean],
      required: false,
      default: 'right',
    },
    modifiers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup() {
    return {}
  },
})
