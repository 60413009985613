










































































































import type { Input, Link, Picture } from '@/inc/types'

import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { push, GtmLayer, parseUTMCookies } from '@/inc/utils'
import { getApiUrl } from '@/inc/app.config'

import FormInput from '@/components/form/Input.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import FormFeedback from '@/components/form/Feedback.vue'

import { ValidationObserver } from 'vee-validate'
import axios from 'axios'

export interface FooterOverlay {
  form: {
    action: string
    subtitle?: string
    confirmation: string
    type: 'newsletter' | 'download' | 'stories'
    title: string
    input: Input[]
    submit: Link
  }
  side: {
    picture: Picture
    title: string
    checklist?: string[]
  }
  result: {
    title: string
    htmltext: string
    label: string
    link?: Link
  }
  location: 'footer' | 'content'
}

export default defineComponent({
  name: 'ChromeFooterOverlay',
  components: {
    FormInput,
    FormCheckbox,
    FormFeedback,
    ValidationObserver,
  },
  props: {
    popup: {
      type: Object as () => FooterOverlay,
      required: true,
    },
  },
  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const sending = ref(false)
    const success = ref(false)
    const fail = ref(false)
    let cookiesData: Record<string, string> = {}
    const { type } = props.popup.form

    const close = () => {
      ctx.emit('close')
    }

    const setCookiesData = () => {
      cookiesData = {
        ...cookiesData,
        ...parseUTMCookies(ctx.root.$cookie),
      }

      // Add campaign cookies to cookies fields
      // Only last LP visited will be used as attribution
      // eg: { name: 'Quote request attribution', value: 'EOY THINK A' }
      const campaignCookie = ctx.root.$cookie.get('CAMPAIGN')

      if (campaignCookie) {
        const attributionCookieValue = campaignCookie
        let attributionCookieName: string

        if (props.popup.form.type === 'newsletter') {
          attributionCookieName = 'newsletterAttribution'
        } else if (props.popup.form.type === 'download') {
          attributionCookieName = 'brochureAttribution'
        } else if (props.popup.form.type === 'stories') {
          attributionCookieName = 'eMagazineAttribution'
        } else {
          return
        }

        cookiesData[attributionCookieName] = attributionCookieValue
      }
    }

    const onSubmit = async () => {
      pushDatalayer()
      sending.value = true

      const { content } = useGetters(['content'])
      const formData = new FormData(form.value!)
      const action = `${getApiUrl()}/forms/${type}`
      const data = {
        action,
        location: process.env.VUE_APP_DOMAIN,
        // Hubspot data
        hutk: ctx.root.$cookie.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: content.value.title,
        // Cookie data
        ...cookiesData,
      }

      for (const pair of formData.entries()) {
        const [name, value] = pair
        if (typeof name === 'string') {
          data[name] = value
        }
      }

      try {
        const res = await axios.post(action, data)

        console.log('RES', res)
        success.value = true
        sending.value = false
      } catch (error) {
        console.error('form:error', error)
        fail.value = true
        sending.value = false
      }
    }

    // Push event to datalayer
    const pushDatalayer = () => {
      const { type } = props.popup.form
      const events = {
        download: 'download_brochure',
        newsletter: 'subscribe_newsletter',
      }
      const layer: GtmLayer = {
        event: events[type],
        ctaLocation: props.popup.location,
        // Add campaign params
      }

      push(layer)
    }

    onMounted(() => {
      setCookiesData()
    })

    return {
      sending,
      success,
      fail,
      close,
      form,
      onSubmit,
      pushDatalayer,
      ...useGetters(['chrome']),
    }
  },
})
