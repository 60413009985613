











































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { FlexiblePicture } from '@/inc/types'

import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'FlexiblePicture',
  props: {
    content: {
      type: Object as () => FlexiblePicture,
      default: () => ({
        fullWidth: true,
        picture: {
          src: '/thumbor/fit-in/1024x/--/uploads/2021/04/lowpoly2.png',
          rawUrl: 'uploads/2021/04/lowpoly2.png',
          alt: '',
          caption: 'Légende',
          description: '',
        },
      }),
    },
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null)

    onMounted(() => {
      if (!props.content.fullWidth) {
        return
      }

      const picture = root.value!.querySelector('.picture__container')

      gsap.fromTo(
        picture,
        {
          y: 0,
          yPercent: -40,
        },
        {
          scrollTrigger: {
            trigger: root.value as HTMLElement,
            scrub: true,
            id: props.content.picture.rawUrl,
          },
          y: 0,
          yPercent: -60,
          ease: 'none',
        }
      )

      root.value!.classList.remove('before-load')
    })

    return {
      root,
    }
  },
})
