











































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import CardSlider from '@/components/flexible/CardSlider.vue'
import CtaExperience from '@/components/cta/Experience.vue'
import CtaMood from '@/components/cta/Mood.vue'
import CtaMoodboard from '@/components/cta/Moodboard.vue'
import CtaPreEncoded from '@/components/cta/PreEncoded.vue'
import CtaStories from '@/components/cta/Stories.vue'
import CtaText from '@/components/cta/Text.vue'
import FlexibleAssert from '@/components/flexible/Assert.vue'
import FlexibleBeforeAfter from '@/components/flexible/BeforeAfter.vue'
import FlexibleBenefits from '@/components/flexible/Benefits.vue'
import FlexibleCaseStudy from '@/components/flexible/CaseStudy.vue'
import FlexibleConditions from '@/components/flexible/Conditions.vue'
import FlexiblePriceDetails from '@/components/flexible/PriceDetails.vue'
import FlexibleDidYouKnow from '@/components/flexible/DidYouKnow.vue'
import FlexibleDuoPicture from '@/components/flexible/DuoPicture.vue'
import FlexibleDuo from '@/components/flexible/Duo.vue'
import FlexibleExperts from '@/components/flexible/Experts.vue'
import FlexibleExpertsTest from '@/components/flexible/ExpertsFlickity.vue'
import FlexibleFollowUp from '@/components/flexible/FollowUp.vue'
import FlexibleGallery from '@/components/flexible/Gallery.vue'
import FlexibleHighlightDuo from '@/components/flexible/HighlightDuo.vue'
import FlexibleHighlighted from '@/components/flexible/Highlighted.vue'
import FlexibleHSection from '@/components/flexible/HighlightedSection.vue'
import FlexibleIconList from '@/components/flexible/IconList.vue'
import FlexibleKeyList from '@/components/flexible/KeyList.vue'
import FlexibleLinks from '@/components/flexible/Links.vue'
import FlexibleMaterials from '@/components/flexible/Materials.vue'
import FlexiblePicture from '@/components/flexible/Picture.vue'
import FlexiblePrepare from '@/components/flexible/Prepare.vue'
import FlexiblePromo from '@/components/flexible/Promo.vue'
import FlexibleQuote from '@/components/flexible/Quote.vue'
import FlexibleSlider from '@/components/flexible/Slider.vue'
import FlexibleTestimonials from '@/components/flexible/Testimonials.vue'
import FlexibleVideo from '@/components/flexible/Video.vue'
import GWysiwyg from '@/components/g/Wysiwyg.vue'
import Personality360 from '@/components/personality/360.vue'
import PersonalityMoodboard from '@/components/personality/Moodboard.vue'
import AWMoodSlider from '@/components/anders-wonen/MoodSlider.vue'

/* eslint-disable camelcase */
const components = {
  assert: FlexibleAssert,
  beforeAfter: FlexibleBeforeAfter,
  benefitBar: FlexibleBenefits,
  blog: GWysiwyg,
  caseStudy: FlexibleCaseStudy,
  conditions: FlexibleConditions,
  cta: FlexibleQuote,
  ctaExperience: CtaExperience,
  ctaMood: CtaMood,
  ctaPromo: FlexiblePromo,
  ctaStories: CtaStories,
  ctaText: CtaText,
  ctaType: CtaPreEncoded,
  ctaMoodboard: CtaMoodboard,
  didYouKnow: FlexibleDidYouKnow,
  duo: FlexibleDuo,
  experts: FlexibleExperts,
  expertstest: FlexibleExpertsTest,
  followUp: FlexibleFollowUp,
  gallery: FlexibleGallery,
  galleryAdjectives: FlexibleGallery,
  galleryWlink: FlexibleGallery,
  highlightDuo: FlexibleHighlightDuo,
  highlighted: FlexibleHighlighted,
  highlighted2: FlexibleHSection,
  iconsList: FlexibleIconList,
  keyFacts: FlexibleKeyList,
  keyFigures: FlexibleKeyList,
  linkedPosts: CardSlider, // slider of cards to posts, vertical
  links: FlexibleLinks, // list of wysiwygs with link icon
  materials: FlexibleMaterials,
  miniGallery: FlexibleGallery,
  moodboard: PersonalityMoodboard,
  moodSlider: AWMoodSlider,
  picture: FlexiblePicture,
  pictureLink: FlexiblePicture,
  pictureTextDuo: FlexibleDuoPicture,
  prepare: FlexiblePrepare,
  priceDetails: FlexiblePriceDetails,
  projectDetails: FlexibleSlider,
  quote: FlexibleQuote,
  testimonials: FlexibleTestimonials,
  video: FlexibleVideo,
  view360: Personality360,
  wysiwyg: GWysiwyg,
}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'GFlexible',

  // props: ['content'],

  components: {
    FlexiblePriceDetails,
  },

  setup() {
    const { content } = useGetters(['content'])

    // Top and bottom border will be disabled on components preceeding and following those
    const disableBorderOnSiblings = ['priceDetails']

    // Returns true if flexibleContent[index] is included in disableBorderOnSiblings array
    const disableComponentBorder = (index: number) => {
      const siblingComponent = content.value.flexibleContent[index]

      if (siblingComponent) {
        return disableBorderOnSiblings.includes(siblingComponent.component)
      }

      return false
    }

    // Note: the  disableBorderXXX are hacks around the lack of :has css selector support.
    // Ideally we would use next sibling + has to select next and previous (:has) sibling and remove their borders.
    const disableBorderBottom = (index: number) =>
      disableComponentBorder(index + 1)
    const disableBorderTop = (index: number) =>
      disableComponentBorder(index - 1)

    return {
      components,
      content,
      disableBorderTop,
      disableBorderBottom,
    }
  },
})
