











































import type { Picture } from '@/inc/types'
import { pushCta } from '@/inc/utils'
import { defineComponent, PropType, ref } from '@vue/composition-api'

export interface Mood {
  title?: string
  displayTitle?: string
  text?: string
  picture: Picture
  verticalPicture?: Picture
  url: string
}

export default defineComponent({
  name: 'AWMoodGrid',
  components: {},
  props: {
    content: {
      type: Object as PropType<Mood>,
      required: true,
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const rootElRef = ref<HTMLElement | null>(null)

    const onLinkClick = () => {
      // Track link as CTA
      if (rootElRef.value) {
        pushCta(
          {
            ctaLabel: props.content.title || props.content.displayTitle,
            ctaType: 'card_mood',
            ctaUrl: props.content.url,
          },
          rootElRef.value
        )
      }
    }

    return { rootElRef, onLinkClick }
  },
})
