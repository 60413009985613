












import { defineComponent } from '@vue/composition-api'
import { FlexibleGallery } from '@/inc/types'
import MagazineGrid from '@/components/magazine/Grid.vue'

import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'FlexibleGallery',
  props: {
    content: {
      type: Object as () => FlexibleGallery,
      Default: {} as FlexibleGallery,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MagazineGrid,
  },
})
