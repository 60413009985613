



















































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'GShare',
  setup() {
    const { chrome, content } = useGetters(['chrome', 'content'])
    const isMobile = ref(false)
    const canShare = ref(false)
    const canCopy = ref(false)
    const copied = ref(false)

    const networks = chrome.value.socialNetworks.filter(
      i => i.type === 'facebook' || i.type === 'linkedin'
    )

    const onResize = (w: number) => (isMobile.value = w <= 960)

    const onClick = (e: MouseEvent) => {
      const el = e.target as HTMLElement

      if (el && el.dataset && el.dataset.share) {
        const type = el.dataset.share
        const uri = el.dataset.url || window.location.href

        if (type === 'facebook') {
          openFacebook(uri, el.dataset.label)
        }

        if (type === 'twitter') {
          openTwitter(uri, el.dataset.text, el.dataset.via, el.dataset.label)
        }

        if (type === 'linkedin') {
          openLinkedin(uri, el.dataset.label)
        }
      }
    }

    const openFacebook = (uri = window.location.href, label = '') => {
      const href = window.encodeURIComponent(uri)
      const url = `https://www.facebook.com/sharer/sharer.php?u=${href}`

      return openPopup(url, label, 575, 300)
    }

    const openLinkedin = (uri = window.location.href, label = '') => {
      const title = window.encodeURIComponent(content.value.title)

      const url = `http://www.linkedin.com/shareArticle?mini=true&url=${uri}&title=${title}`

      return openPopup(url, label, 550, 255)
    }

    const openTwitter = (
      uri = window.location.href,
      rawText = '',
      account = '',
      label = ''
    ) => {
      const text = window.encodeURIComponent(rawText)
      const via = window.encodeURIComponent(account)

      const href = window.encodeURIComponent(uri)
      let url = `https://twitter.com/intent/tweet?url=${href}`

      if (text.length !== 0) {
        url += `&text=${text}`
      }

      if (via.length !== 0) {
        url += `&via=${via}`
      }

      return openPopup(url, label, 550, 255)
    }

    const openPopup = (url, title, width, height) => {
      const left = (window.screen.width - width) / 2
      const top = (window.screen.height - height) / 2

      return window.open(
        url,
        title,
        `status=no,
         resizable=yes,
         height=${height},
         width=${width},
         top=${top},left=${left},
         toolbar=no,
         menubar=no,
         scrollbars=no,
         location=no,
         directories=no`
      )
    }

    const share = async () => {
      const data = {
        title: content.value.title,
        text: content.value.title,
        url: window.location.href,
      }

      try {
        await navigator.share(data)
      } catch (err) {
        //
      }
    }

    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          copied.value = true

          window.addEventListener('click', () => (copied.value = false), {
            once: true,
          })
        })
        .catch(() => {
          console.log('clipboard failed')
        })
    }

    onMounted(() => {
      isMobile.value = window.innerWidth <= 960

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (navigator.share) {
        canShare.value = true
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (navigator.clipboard) {
        canCopy.value = true
      }
    })

    return {
      chrome,
      networks,
      isMobile,
      canShare,
      canCopy,
      onClick,
      share,
      onResize,
      copyToClipboard,
      copied,
    }
  },
})
