



































































import { defineComponent } from '@vue/composition-api'
import { useGetters, useActions } from '@u3u/vue-hooks'

import { Showroom } from '@/inc/types'
import { push, getPathName, GtmLayer } from '@/inc/utils'
import { FirstStep } from '@/inc/store/modules/steps'
import { StorageUtils } from '@/inc/plugins/storage'

export default defineComponent({
  name: 'Showroom',
  props: {
    content: {
      type: Object as () => Showroom,
      required: true,
    },
  },
  setup(props, ctx) {
    const { addFirstStep } = useActions('steps', ['addFirstStep']) as {
      addFirstStep: (payload: {
        data: FirstStep
        storage: StorageUtils
      }) => Promise<void>
    }
    const pushPhone = () => {
      const layer: GtmLayer = {
        event: 'click_showroom',
        clickType: 'phone',
        clickValue: props.content.phone?.label as string | undefined,
        showroomName: props.content.value,
      }
      push(layer)
    }

    const pushAddress = () => {
      const { address, value } = props.content
      const { street, number, zip, city, country } = address

      const layer: GtmLayer = {
        event: 'click_showroom',
        clickType: 'address',
        clickTypeValue: `${street} ${number}, ${zip} ${city}, ${country}`,
        showroomName: value,
      }
      push(layer)
    }

    const onClick = () => {
      const { value, address, title } = props.content
      const { zip } = address
      const showroom = {
        zip,
        value,
        title,
      }
      addFirstStep({
        data: showroom,
        storage: ctx.root.$options.$storage as StorageUtils,
      })

      // Track first step
      push({
        event: 'generate_lead_step1',
        showroomName: value,
      })
    }

    return {
      pushPhone,
      pushAddress,
      getPathName,
      onClick,
      ...useGetters(['chrome']),
    }
  },
})
