


















































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  Ref,
  watch,
} from '@vue/composition-api'
import gsap from 'gsap'
import { debounce } from 'throttle-debounce'
import { prefersReducedMotion, isTouchOnly } from '@/inc/utils'
import Draggable from 'gsap/dist/Draggable'
import InertiaPlugin from 'gsap/dist/InertiaPlugin'

export interface CarouselItem {
  carouselKey?: string
  [key: string]: unknown
}

export interface CarouselItemProxy {
  content: CarouselItem | unknown
  enterProgress: number
  leaveProgress: number
}

// If `from` is ommited, it will default to -Infinity.
// If `until` if ommited, it will default to +Infinity.
export interface BreakpointRange {
  from?: number
  until?: number
}

// Minimal distance below which prev / next control buttons should be disabled.
const SCROLL_THRESHOLD = 10
const SCROLL_LERP_AMOUNT = 0.1
const SCROLL_LERP_THRESHOLD = 1

const closest = (target: number, candidates: number[]) =>
  candidates.reduce((prev, curr) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  )

export default defineComponent({
  name: 'carousel',
  props: {
    // An array of items content
    items: {
      type: Array as PropType<CarouselItem[]>,
      required: true,
      validator: (value: CarouselItem[]) => {
        value.forEach(item => {
          if (!item.carouselKey) {
            console.warn(
              // eslint-disable-next-line max-len
              '🎠 Carousel item does not have a carouselKey property. This will lead to update errors if the carousel content is dynamic.'
            )
          }
        })

        return true
      },
    },

    // Whether or not component should will automatically take care of handling full bleed effect
    // by applying padding and negative margin to its root until it is aligned with fullBleedContainer element.
    fullBleed: {
      type: Boolean,
      default: true,
      required: false,
    },

    // The element acting as container for the full bleed effect.
    // Will default to window.document.body if null
    // Can be an HTMLElement or null
    // TODO: Proper typing
    fullBleedParent: {
      // type: HTMLElement, // ⚠️ Throw ReferenceError: HTMLElement is not defined coming from babel-loader...
      required: false,
      default: null,
    },

    // Whether and when carousel should display previous and next controls buttons.
    // ℹ️ Those are currently only displayed on non touch devices.
    // false -> never
    // true -> always (if enough items)
    // BreakpointRange -> false outside range, true inside range
    prevNext: {
      type: Boolean || (Object as PropType<BreakpointRange>),
      required: false,
      default: false,
    },

    // Whether and when carousel should display bullet points navigation.
    // ℹ️ If too much items, it is recommended to use a scrollbar instead.
    // false -> never
    // true -> always (if enough items)
    // BreakpointRange -> false outside range, true inside range
    // false -> never | true -> always (if enough items) | BreakpointRange -> false outside range, true inside
    bulletPoints: {
      type: Boolean || (Object as PropType<BreakpointRange>),
      required: false,
      default: false,
    },

    // Optional individual item transition function to run when an item is added to carousel
    itemTransitionEnter: {
      type: Function as PropType<(el: Element, done: () => void) => void>,
      required: false,
    },

    // Optional individual item transition function to run when an item is removed from carousel
    itemTransitionLeave: {
      type: Function as PropType<(el: Element, done: () => void) => void>,
      required: false,
    },

    // How the slides should align relative to scroller viewport when scrolling programmatically
    alignement: {
      type: String as PropType<'left' | 'center'>,
      default: 'left',
      required: false,
    },

    // Enable drag to scroll on desktop
    drag: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props, ctx) {
    const itemsProxy: Ref<CarouselItemProxy[]> = ref([])

    const rootRef: Ref<HTMLElement | undefined> = ref()
    const carouselItemElsRef: Ref<HTMLElement[]> = ref([])
    // scrollerRef is referencing the TransitionGroup component
    const scrollerRef: Ref<{ $el: Element } | undefined> = ref(undefined)

    // eslint-disable-next-line id-length
    let scrollerInlineOffsetStart = 0
    let scrollerInlineOffsetEnd = 0

    // The slide consired "current" is the one which has the left edge the closest from the carousel viewport left edge.
    const currentSlideIndex = ref(0)

    const currentBulletIndex = ref(0)
    const showBulletPoints = ref(props.bulletPoints)
    const bulletPointsItems: Ref<number[]> = ref([])

    const prevButtonDisabled = ref(true)
    const nextButtonDisabled = ref(false)
    const showPrevNext = ref(props.prevNext)
    const isScrollable = ref(false)
    let resizeObserver: undefined | ResizeObserver = undefined

    // Horizontal scroll tagret value to lerp to.
    // Used during programmatically triggered scroll (prev/next buttons).
    let scrollLerpTarget = 0

    // Lerping directly the scrollerRef.value.scrollLeft value does not work as expected.
    // Is suspect the browser is doing some rounding behind the scene.
    // To bypasse this issue, we're using a proxy variable to apply lerping.
    let scrollLeftProxy = 0

    // eslint-disable-next-line id-length
    let updateCurrentIndexOnScroll = true

    let draggable: Draggable | undefined = undefined
    // eslint-disable-next-line id-length
    let draggablePreviousDistance = 0

    // Reacts to change in the item props then rebuild items proxy list.
    watch(
      () => props.items,
      () => {
        updateItemsProxy()
      }
    )

    // Reacts to DOM changes. Register new scroll triggers, ResizeObservers, anything DOM related...
    // Update isScrollable.
    watch(carouselItemElsRef, () => {
      carouselItemElsRef.value.forEach(el => {
        // Let resize oberserver know about new elements
        // Will ultimately trigger callback if element sizing changes during which we'll check
        // if carousel is scrollable.
        resizeObserver?.observe(el)
      })

      onResize()
    })

    watch(isScrollable, () => {
      updatePrevNextVisibility()
      updateBulletPointsVisibility()
    })

    onBeforeMount(() => {
      resizeObserver = new ResizeObserver(onResize)

      updateItemsProxy()
    })

    onMounted(() => {
      ctx.root.$nextTick(() => {
        updateFullBleed()
        updateIsScrollable()
        updatePrevNextVisibility()
        updateBulletPointsVisibility()
        updateBulletPagination()

        if (props.drag) {
          initDraggable()
        }
      })
    })

    onUnmounted(() => {
      onResize.cancel()
      gsap.ticker.remove(lerpScrollPosition)
      draggable?.kill()
    })

    const onResize = debounce(100, () => {
      updateIsScrollable()
      updateFullBleed()
      updatePrevNextVisibility()
      updateBulletPointsVisibility()
      updateBulletPagination()
      onScrollerScroll()
      toggleDraggable()
    })

    const goToSlide = (index: number, noLerp = false) => {
      const slideEl = carouselItemElsRef.value[index] as HTMLElement

      if (!slideEl) {
        throw new Error('🎠 - Target element does not exists')
      }

      const desiredScrollPosition = getDesiredScrollPosition(slideEl)

      // Set currentSlideIndex right away in case of programmatically triggered scroll
      currentSlideIndex.value = index

      scrollTo(desiredScrollPosition, noLerp)
    }

    const scrollTo = (x: number, noLerp = false) => {
      if (!scrollerRef.value?.$el) {
        throw new Error('🎠 - No scroller container to scroll')
      }

      // Use Math.min to avoid scrolling outside of scrollable area
      const desiredScrollPosition = Math.min(
        x,
        scrollerRef.value.$el.scrollWidth - scrollerRef.value.$el.clientWidth
      )

      // Kill previous tween if any + animate scroll position to desired value
      if (prefersReducedMotion() || noLerp) {
        scrollerRef.value.$el.scrollLeft = desiredScrollPosition
      } else {
        // Bypass automatic current index update on scroll during programmatically initiated scroll
        updateCurrentIndexOnScroll = false

        // Update desired scroll position
        scrollLerpTarget = desiredScrollPosition

        // Remove froom ticker previously added interpolation function (if any)
        gsap.ticker.remove(lerpScrollPosition)

        // Before start lerping, set scrollerProxy to the actual current scroll value.
        // Prevents jumps when mixing scrolling methods (ie using prevnext, then scrolling, then prevnext again)
        scrollLeftProxy = scrollerRef.value.$el.scrollLeft

        // Start applying interpolation on each frame
        gsap.ticker.add(lerpScrollPosition)
      }
    }

    /**
     * Compute the desired offset needed to be applied to scroller so that item is aligned on wrapper
     * @param item HTMLElement to scroll to
     */
    const getDesiredScrollPosition = (item: HTMLElement) => {
      if (!item) {
        throw new Error('🎠 - No element to scroll to')
      }

      if (props.alignement === 'center') {
        if (scrollerRef.value) {
          // prettier-ignore
          const scroll = item.offsetLeft - (scrollerRef.value.$el.clientWidth / 2) + (item.clientWidth / 2)

          return Math.min(
            Math.max(0, scroll),
            scrollerRef.value.$el.scrollWidth
          )
        }

        console.warn('Cannot align center because scrollerRef is null')
      }

      return item.offsetLeft - scrollerInlineOffsetStart
    }

    /**
     * Update the current slide index value based on which one is the closest from the origin.
     */
    const updateCurrentSlideIndex = () => {
      if (!scrollerRef.value?.$el) {
        return
      }

      const origin =
        scrollerRef.value.$el.scrollLeft + scrollerInlineOffsetStart

      // Find which the element which offsetLeft is the closest to origin
      const closestEl = carouselItemElsRef.value.reduce((prev, curr) =>
        Math.abs(curr.offsetLeft - origin) < Math.abs(prev.offsetLeft - origin)
          ? curr
          : prev
      )

      if (!closestEl || !closestEl.parentNode) {
        return
      }

      // Find the index of closest element
      const closestIndex = Array.from(closestEl.parentNode.children).indexOf(
        closestEl
      )

      currentSlideIndex.value = closestIndex
    }

    /**
     * Update the prevButtonDisabled reference based on scrolled distance.
     * Set ref to true if scrolled distance < scroll threshold.
     */
    const updatePrevButtonDisabled = () => {
      if (scrollerRef.value?.$el) {
        prevButtonDisabled.value =
          scrollerRef.value.$el.scrollLeft < SCROLL_THRESHOLD
      } else {
        prevButtonDisabled.value = false
      }
    }

    /**
     * Update the nextButtonDisabled reference based on remaining scroll distance.
     * Set ref to true if remaining scroll distance < scroll threshold.
     */
    const updateNextButtonDisabled = () => {
      if (scrollerRef.value?.$el) {
        const { scrollWidth, scrollLeft, clientWidth } = scrollerRef.value.$el

        nextButtonDisabled.value =
          Math.abs(scrollWidth - scrollLeft - clientWidth) < SCROLL_THRESHOLD
      } else {
        nextButtonDisabled.value = false
      }
    }

    /**
     * Toggle the visibility of the prev next controls based on specified options and current viewport width.
     */
    const updatePrevNextVisibility = () => {
      if (isScrollable.value) {
        if (typeof props.prevNext === 'boolean') {
          // If prevNext is of type boolean, set showPrevNext value directly
          showPrevNext.value = props.prevNext
        } else if (typeof props.prevNext === 'object') {
          // Otherwise, if it's a range, showPrevNext value based on defined range and current innerWidth value
          const options = props.prevNext as BreakpointRange

          const from =
            typeof options.from === 'undefined' ? -Infinity : options.from

          const to =
            typeof options.until === 'undefined' ? Infinity : options.until

          showPrevNext.value =
            window.innerWidth >= from && window.innerWidth <= to
        }
      } else {
        // Always set to false if scroller has not enough content to be scrolled
        showPrevNext.value = false
      }
    }

    /**
     * Toggle the visibility of the bullet points based on specified options and current viewport width.
     */
    // eslint-disable-next-line id-length
    const updateBulletPointsVisibility = () => {
      if (isScrollable.value) {
        if (typeof props.bulletPoints === 'boolean') {
          // If bulletPoints is of type boolean, set showBulletPoints value directly
          showBulletPoints.value = props.bulletPoints
        } else if (typeof props.bulletPoints === 'object') {
          // Otherwise, if it's a range, showBulletPoints value based on defined range and current innerWidth value
          const options = props.bulletPoints as BreakpointRange

          const from =
            typeof options.from === 'undefined' ? -Infinity : options.from

          const to =
            typeof options.until === 'undefined' ? Infinity : options.until

          showBulletPoints.value =
            window.innerWidth >= from && window.innerWidth <= to
        }
      } else {
        // Always set to false if scroller has not enough content to be scrolled
        showBulletPoints.value = false
      }
    }

    const updateIsScrollable = () => {
      if (!scrollerRef.value?.$el) {
        return
      }

      // true if content is larger than scroller
      isScrollable.value =
        scrollerRef.value.$el.scrollWidth > scrollerRef.value.$el.clientWidth
    }

    /**
     * Creates a new itemsProxy array based on items prop.
     * itempsProxy entries embark item as well as additional properties.
     */
    const updateItemsProxy = () => {
      if (Array.isArray(props.items)) {
        itemsProxy.value = props.items?.map(item => ({
          content: item,
          enterProgress: 0,
          leaveProgress: 0,
        }))
      } else {
        itemsProxy.value = []
      }
    }

    const updateFullBleed = () => {
      if (!props.fullBleed || !rootRef.value) {
        return
      }

      const fullbleedParent: HTMLElement =
        (props.fullBleedParent as unknown as HTMLElement) ||
        window.document.body

      if (!fullbleedParent) {
        throw new Error('🎠 - Full bleed container element does not exists')
      }

      let fullBleedParentLeft = 0
      let fullBleedParentRight = fullbleedParent.offsetWidth

      // fullbleedParent is the body element by default.
      // Since we know that the body left value is always 0, no need to compute it in that case.
      // If we're using another element than the body as root, then we need to retrieve it.
      if (fullbleedParent.tagName !== 'BODY') {
        const fullbleedParentBBox = fullbleedParent.getBoundingClientRect()
        fullBleedParentLeft = fullbleedParentBBox.left
        fullBleedParentRight = fullbleedParentBBox.right
      }

      const BBox = rootRef.value.getBoundingClientRect()
      scrollerInlineOffsetStart = BBox.left - fullBleedParentLeft
      scrollerInlineOffsetEnd = fullBleedParentRight - BBox.right

      rootRef.value.style.setProperty(
        '--scroller-inline-padding-start',
        `${scrollerInlineOffsetStart}px`
      )

      rootRef.value.style.setProperty(
        '--scroller-inline-padding-end',
        `${scrollerInlineOffsetEnd}px`
      )
    }

    const onClickPrev = () => {
      if (prevButtonDisabled.value) {
        return
      }

      if (currentSlideIndex.value === 0) {
        // Fix the following scenario:
        // currentSlide === 0 but small screen size so slide content is partially outside window viewport.
        goToSlide(0)
      } else if (currentSlideIndex.value > 0) {
        goToSlide(currentSlideIndex.value - 1)
      }
    }

    const onClickNext = () => {
      if (
        !nextButtonDisabled.value &&
        currentSlideIndex.value < carouselItemElsRef.value.length - 1
      ) {
        goToSlide(currentSlideIndex.value + 1)
      }
    }

    // TODO: Throttle
    const onScrollerScroll = () => {
      updateNextButtonDisabled()
      updatePrevButtonDisabled()
      if (updateCurrentIndexOnScroll) {
        updateCurrentSlideIndex()
      }
      updateActiveBulletIndex()
    }

    const updateBulletPagination = () => {
      if (!scrollerRef.value?.$el) {
        return
      }

      bulletPointsItems.value = []

      const scrollerWidth = scrollerRef.value.$el.scrollWidth
      const scrollerViewportWidth =
        scrollerRef.value.$el.clientWidth -
        scrollerInlineOffsetStart -
        scrollerInlineOffsetEnd

      const count = Math.ceil(scrollerWidth / scrollerViewportWidth)

      if (count > props.items.length) {
        carouselItemElsRef.value.forEach(el => {
          bulletPointsItems.value.push(getDesiredScrollPosition(el))
        })
      } else {
        const incr =
          (scrollerWidth - scrollerRef.value.$el.clientWidth) / (count - 1)

        for (let i = 0; i < count; i++) {
          bulletPointsItems.value.push(incr * i)
        }
      }
    }

    const updateActiveBulletIndex = () => {
      if (!scrollerRef.value || bulletPointsItems.value.length < 1) {
        return
      }

      currentBulletIndex.value = bulletPointsItems.value.indexOf(
        closest(scrollerRef.value.$el.scrollLeft, bulletPointsItems.value)
      )
    }

    /**
     * Lerp the scroller scrollLeft value until it reaches scrollLerpTarget.
     * Meant to be called each frame (using gsap.ticker).
     * Used ONLY during progammatically triggered scroll. Do NOT lerp position when using native scrolling.
     * Lerping scrollLeft value to a target feels more natural and responsive
     * than using tween when spamming the prev/next buttons.
     * (in this case, tweens feels staggery)
     */
    const lerpScrollPosition = () => {
      if (scrollerRef.value) {
        // Lerping directly the scrollerRef.value.scrollLeft value does not work as expected.
        // I suspect the browser is doing some rounding behind the scene.
        // To bypasse this issue, we're using a proxy variable to apply lerping.
        scrollLeftProxy = gsap.utils.interpolate(
          scrollLeftProxy,
          scrollLerpTarget,
          SCROLL_LERP_AMOUNT
        )
        scrollerRef.value.$el.scrollLeft = scrollLeftProxy

        // If scroll offset is close enough to value target, stop lerping
        const distance = Math.abs(scrollLeftProxy - scrollLerpTarget)
        if (distance <= SCROLL_LERP_THRESHOLD) {
          // stop applying lerping
          gsap.ticker.remove(lerpScrollPosition)

          // Programmatically triggered scroll has ended, restart updating current index during scroll
          updateCurrentIndexOnScroll = true
        }
      }
    }

    /**
     * If the current item has a `carouselKey` property, this will be used as key.
     * If not, index number will be used as fallback.
     */
    const getCarouselItemKey = (item: CarouselItemProxy, index: number) =>
      (item.content as CarouselItem).carouselKey || index.toString()

    /**
     * If specified, plays animation when an item is added to itemsProxy
     */
    const onItemEnter = (el: Element, done: () => void) => {
      if (props.itemTransitionEnter) {
        props.itemTransitionEnter(el, done)
      } else {
        done()
      }
    }

    /**
     * If specified, plays animation when an item is removed from itemsProxy
     */
    const onItemLeave = (el: Element, done: () => void) => {
      if (props.itemTransitionLeave) {
        props.itemTransitionLeave(el, done)
      } else {
        done()
      }
    }

    const initDraggable = () => {
      gsap.registerPlugin(Draggable)
      gsap.registerPlugin(InertiaPlugin)

      draggable = new Draggable(document.createElement('div'), {
        trigger: scrollerRef.value!.$el,
        throwProps: true,
        onDrag,
        onDragStart,
        onThrowUpdate: onDrag,
      })
      toggleDraggable()
    }

    const onDrag = () => {
      if (draggable && scrollerRef.value) {
        const distance = draggable.startX - draggable.x

        // Scroll by distance dragged since last frame
        scrollerRef.value.$el.scrollLeft += distance - draggablePreviousDistance

        draggablePreviousDistance = distance
      }
    }

    const onDragStart = () => {
      if (draggable) {
        draggablePreviousDistance = draggable.startX - draggable.x
      }
    }

    const toggleDraggable = () => {
      if (isTouchOnly()) {
        draggable?.disable()
      } else {
        draggable?.enable()
      }
    }

    return {
      rootRef,
      scrollerRef,
      carouselItemElsRef,
      currentSlideIndex,
      goToSlide,
      onClickPrev,
      onClickNext,
      onScrollerScroll,
      showPrevNext,
      showBulletPoints,
      bulletPointsItems,
      prevButtonDisabled,
      nextButtonDisabled,
      onResize,
      itemsProxy,
      isScrollable,
      scrollTo,
      currentBulletIndex,
      onItemEnter,
      onItemLeave,
      getCarouselItemKey,
    }
  },
})
