


































































































































































































import {
  defineComponent,
  ref,
  onMounted,
  // onUnmounted,
} from '@vue/composition-api'
import { FlexibleGallery } from '@/inc/types'

// import { gsap } from 'gsap'
// import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
// gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'FlexibleExperts',
  props: {
    content: {
      type: Object as () => FlexibleGallery,
      required: true,
    },
  },
  setup() {
    const popup = ref()
    const list = ref<null | HTMLElement>(null)
    const total = ref(0)
    const current = ref(0)
    const prevButton = ref()
    const nextButton = ref()
    const popupContent = ref<Record<string, unknown> | null>(null)
    const canSlide = ref(true)
    const index = ref(0)
    let isMobile = true
    let flickity

    onMounted(async () => {
      const Flickity = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'flickity'
      ).then(module => module.default)

      flickity = new Flickity(list.value as HTMLElement, {
        cellSelector: '.flexible-experts__slide-outer',
        pageDots: false,
        prevNextButtons: false,
        cellAlign: 'left',
        wrapAround: false,
        contain: true,
        freeScroll: true,
        draggable: true,
        // draggable: isMobile,
        // groupCells: isMobile ? false : 2,
        groupCells: false,
      })

      isMobile = window.innerWidth <= 1024

      const updateProgress = (i, total) => {
        // if (index >= total - (isMobile ? 6 : 3)) {
        index.value = i

        console.log('test', i, total)

        if (i >= total - (isMobile ? 1 : 2)) {
          canSlide.value = false
        } else {
          canSlide.value = true
        }
      }

      total.value = flickity.cells.length
      current.value = flickity.selectedIndex

      flickity.on('change', () => {
        updateProgress(flickity.selectedIndex, flickity.cells.length)
      })

      flickity.on('dragStart', () => {
        if (list.value) {
          list.value.classList.add('is-dragging')
        }
      })
      flickity.on('dragEnd', () => {
        if (list.value) {
          list.value.classList.remove('is-dragging')
        }
      })

      const { innerWidth } = window

      console.log('width', innerWidth)
    })

    const onClick = expert => {
      openPopup(expert)
    }

    const closePopup = () => (popupContent.value = null)
    const openPopup = expert => {
      popupContent.value = expert
    }

    const onResize = (width: number) => {
      const newIsMobile = width <= 1024

      if (isMobile !== newIsMobile) {
        isMobile = newIsMobile
      }

      isMobile = newIsMobile
    }

    const prev = () => {
      flickity.previous()
    }

    const next = () => {
      flickity.next()
    }

    return {
      onClick,
      popup,
      popupContent,
      closePopup,
      list,
      onResize,
      isMobile,
      prev,
      next,
      prevButton,
      nextButton,
      canSlide,
      index,
    }
  },
})
