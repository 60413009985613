














































import type { Route } from 'vue-router'

import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Vlitejs from 'vlitejs'
import VlitejsYoutube from 'vlitejs/dist/providers/youtube'
import { debounce } from 'throttle-debounce'

import DevGrid from '@/components/DevGrid.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import PageTransition from '@/components/PageTransition.vue'

import NotFound from '@/views/NotFound.vue'

import { push, getCookieConsent, setVHCSSVariable } from '@/inc/utils'
import { StorageUtils } from '@/inc/plugins/storage'
import { isUnderMaintenance } from '@/inc/app.config'
import favicon from '@/inc/custom/favicon'

const RESIZE_DEBOUNCE = 150

export default Vue.extend({
  name: 'app',
  components: {
    ChromeHeader,
    DevGrid,
    EnvSwitcher,
    NotFound,
    ChromeFooter,
    PageTransition,
  },
  data() {
    return {
      hasError: false,
      isUnderMaintenance,
      body: {} as HTMLElement,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template', 'content']),
    ...mapGetters('ui', ['videoId']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
      updateCountry: 'UPDATE_COUNTRY',
    }),
    ...mapActions(['fetchChrome']),

    /* Tracking */
    // Set all marketing cookies
    setMarketingCookies(route: Route) {
      if (
        !route ||
        !getCookieConsent(this.$root.$cookie.get('CookieConsent'))
      ) {
        console.log('🍪❌ No marketing cookies for you')

        return
      }

      console.log('🍪 Set marketing cookies')

      this.setUTMCookies(route)
      this.setCampaignCookie(route)
    },
    // Add utm cookie based on utm string
    // eg: camber.be/fr/?utm_source=facebook
    setUTMCookies(route: Route) {
      const { query } = route
      let utmValue = ''

      if (!query || !route.fullPath.includes('utm_')) {
        return
      }

      for (const [key, value] of Object.entries(query)) {
        if (key.includes('utm_')) {
          if (utmValue.length > 0) {
            utmValue += '&'
          }

          utmValue += `${key}=${value}`
        }
      }

      // Set expire time
      const now = new Date()
      const expireTime = now.setMonth(now.getMonth() + 6)

      this.$root.$cookie.set('utm', utmValue, {
        expires: new Date(expireTime),
        path: '/',
      })
    },
    // Add campaign cookie if user has been the campaign landing pages
    setCampaignCookie(route: Route) {
      /* eslint-disable quote-props */
      const landingPages = {
        // BTS
        'chambre-enfant-evolutive-sur-mesure': 'bts2024 SETTLERS SEE',
        'futureproof-kinderkamer-maatkasten': 'bts2024 SETTLERS SEE',
        'rangements-multifonctionnels-durables': 'bts2024 INVESTORS SEE',
        'multifunctionele-duurzame-maatkasten': 'bts2024 INVESTORS SEE',
        'calme-maison-rangements-sur-mesure': 'bts2024 DOWNSIZERS SEE',
        'thuiskomen-rust-maatkasten': 'bts2024 DOWNSIZERS SEE',
        'action-back-to-school': 'bts2024 THINK',
        'back-to-school-actie': 'bts2024 THINK',
        'showroom-de-rangement-sur-mesure-neupre': 'bts2024 DO',
        'showroom-de-rangement-sur-mesure-uccle': 'bts2024 DO',
        'showroom-kasten-op-maat-gent': 'bts2024 DO',
        'showroom-kasten-op-maat-nieuwpoort': 'bts2024 DO',
      }
      /* eslint-enable quote-props */

      const { slug, sub, endpoint, category, pathMatch } = route.params
      const landingPage =
        (!sub && landingPages[slug]) ||
        landingPages[sub] ||
        landingPages[pathMatch] ||
        landingPages[`categorie-${category}`] ||
        landingPages[endpoint] ||
        landingPages[`${endpoint}/${category}`]

      // Set expire time
      const now = new Date()
      const expireTime = now.setMonth(now.getMonth() + 6)

      if (landingPage) {
        this.$root.$cookie.set('CAMPAIGN', landingPage, {
          expires: new Date(expireTime),
          path: '/',
        })

        console.log(`🍪🍪🍪 ${landingPage} 🍪🍪🍪`, 'from landing page')
      } else if (this.content && this.content.utm_cookie) {
        // TODO: change utm_cookie to campaignCookie ?
        this.$root.$cookie.set('CAMPAIGN', this.content.utm_cookie, {
          expires: new Date(expireTime),
          path: '/',
        })

        console.log(`🍪 ${this.content.utm_cookie} 🍪`, 'from content')
      }
    },

    onResize() {
      setVHCSSVariable()
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    this.$logger.trace('[app:created]', this.$$type)

    if (!this.$isServer) {
      this.hasError =
        document.querySelector('[data-server-rendered].error') !== null
    }

    if (this.hasError) {
      return
    }

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }

    if (this.$isServer) {
      this.updateCountry(this.$root.$options.$country)
    } else {
      this.updateCountry(document.documentElement.dataset.country)
    }
  },
  beforeMount() {
    // Provider needs to be registered only once
    Vlitejs.registerProvider('youtube', VlitejsYoutube)
  },
  mounted() {
    this.$logger.trace('[app:mounted]')
    this.body = document.body
    this.$on('scroll-disabled', () => (this.body.style.overflow = 'hidden'))
    this.$on('scroll-enabled', () => this.body.removeAttribute('style'))

    // Add marketing cookies
    this.setMarketingCookies(this.$router.currentRoute)

    // When the user changes their cookies
    window.addEventListener('consent.onetrust', () => {
      this.setMarketingCookies(this.$router.currentRoute)
    })

    // Before the user leaves the website
    window.addEventListener('beforeunload', () => {
      this.setMarketingCookies(this.$router.currentRoute)
    })

    // Between pages...
    this.$router.beforeEach((to: Route, from: Route, next: () => void) => {
      this.setMarketingCookies(from)
      this.setMarketingCookies(to)
      ;(document.activeElement! as HTMLElement).blur()
      next()
    })

    // When a popup is opened
    this.$root.$ee.$on('popup:open', () => {
      // We need to create a mock route as the route is not modified, only the location
      const parts = window.location.href.split('/')

      this.setMarketingCookies({
        params: {
          slug:
            parts[parts.length - 1] === ''
              ? parts[parts.length - 2]
              : parts[parts.length - 1],
        },
        path: window.location.pathname,
        fullPath: window.location.pathname,
        hash: '',
        matched: [],
        query: {},
      })
    })

    this.$root.$emit('page:ready')

    this.onResize = debounce(RESIZE_DEBOUNCE, this.onResize)
    window.addEventListener('resize', this.onResize)
    setVHCSSVariable()
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)

      // Activate Optimize
      push({ event: 'optimize.activate' })
    },
  },
  head() {
    return {
      ...this.meta,
      link: favicon?.link.concat(this.meta?.link || []),
      meta: favicon?.meta.concat(this.meta?.meta || []),
    }
  },
})
