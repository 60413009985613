import { render, staticRenderFns } from "./LoadingDots.vue?vue&type=template&id=17a253f0&scoped=true"
import script from "./LoadingDots.vue?vue&type=script&lang=ts"
export * from "./LoadingDots.vue?vue&type=script&lang=ts"
import style0 from "./LoadingDots.vue?vue&type=style&index=0&id=17a253f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a253f0",
  null
  
)

export default component.exports