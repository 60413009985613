




























import { defineComponent } from '@vue/composition-api'
import { PCard } from '@/inc/types'

export default defineComponent({
  name: 'ProjectCard',
  props: {
    content: {
      type: Object as () => PCard,
      default: () => ({
        pictures: [
          {
            src: '/thumbor/fit-in/1024x/--/uploads/2021/04/temp1.jpeg',
            rawUrl: 'uploads/2021/04/temp2.jpeg',
            alt: '',
            caption: '',
            description: '',
          },
        ],
        title:
          'Dressing de <em>Sacha et Claire</em> à Evere ou à Woluwe tant quon y est',
        url: '#',
        categories: ['dressing'],
      }),
    },
  },
  setup(props, ctx) {
    const onLoad = (e: Event) => {
      ctx.emit('img:load', e.target)
    }

    const onClick = () => {
      const project = { ...props.content }

      ctx.emit('card:click', project)
    }

    return { onLoad, onClick }
  },
})
