


























import { defineComponent } from '@vue/composition-api'

import FlexibleVideo from '@/components/flexible/Video.vue'

export default defineComponent({
  name: 'FlexibleDuo',
  components: {
    FlexibleVideo,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
})
