













import { defineComponent } from '@vue/composition-api'

import MagazineCard from '@/components/magazine/Card.vue'
import FlexibleSlider from '@/components/flexible/Slider.vue'

export default defineComponent({
  name: 'FlexibleCardSlider',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  components: {
    MagazineCard,
    FlexibleSlider,
  },

  setup() {
    return {}
  },
})
