































































































































import type { FlexibleGallery } from '@/inc/types'

import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { adjectives } from '@/inc/data/adjectives'
import { defaultSets } from '@/inc/directives/src'

import { gsap } from 'gsap'
import { debounce } from 'throttle-debounce'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'FlexibleGallery',
  props: {
    content: {
      type: Object as () => FlexibleGallery,
      required: true,
    },
    onOverlayer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Refs
    const rootElRef = ref<HTMLElement | null>(null)
    const galleryElRef = ref<HTMLElement | null>(null)
    const picturesElRef = ref<HTMLElement[] | null>(null)
    const imgElsRef = ref<HTMLImageElement[] | null>(null)
    const adjectivesElRef = ref<HTMLElement[] | null>(null)
    const adjectivesData = ref()

    // Variables
    const items = props.content.gallery || props.content.items
    const mini = props.content.items && props.content.items.length > 0
    const name = props.content.title || 'gallery'
    let tl = gsap.timeline()
    let totalWidth = 0
    let windowMiddle = 0
    let windowRatio = 0
    let maxLength = 0

    const toggle = () => {
      if (tl.isActive()) {
        tl.pause()
      } else {
        tl.resume()
      }
    }

    const play = () => {
      totalWidth = 0
      windowMiddle = window.innerWidth / 2
      // Defines the 'intensity' of the parallax
      windowRatio = gsap.utils.clamp(
        8,
        40,
        Math.round(window.innerWidth * 0.01)
      )

      if (props.onOverlayer) {
        tl = gsap.timeline()
      } else {
        tl = gsap.timeline({
          scrollTrigger: {
            id: name,
            trigger: galleryElRef.value as Element,
            toggleActions: 'play pause resume pause',
          },
        })
      }

      // Add each picture width to the total width
      picturesElRef.value!.forEach((pic: HTMLElement) => {
        tl.set(pic, {
          x: totalWidth,
        })

        totalWidth += pic.clientWidth

        maxLength = pic.clientWidth > maxLength ? pic.clientWidth : maxLength
      })

      tl.to(
        picturesElRef.value,
        {
          duration: gsap.utils.clamp(250, items.length * 30, items.length * 30),
          ease: 'none',
          x: `-=${totalWidth}`,
          // Still needed?
          // z: 0,
          modifiers: {
            x: gsap.utils.unitize(x =>
              x < -maxLength ? (x % totalWidth) + totalWidth : x
            ),
          },
          repeat: -1,
          onUpdate() {
            if (!mini) {
              // Add parallax
              this.targets().forEach(pic => {
                const img = pic.querySelector('img')
                const adjectives = pic.querySelectorAll('.picture__adjectif')
                const data = pic.getBoundingClientRect()
                const offset = (windowMiddle - data.x - 100) / windowRatio

                gsap.set(img, { x: offset, scale: 1.3 })

                if (adjectives && adjectives.length) {
                  gsap.set(adjectives, { x: offset, delay: 0.1 })
                }
              })
            }
          },
        },
        0
      )
    }

    const onAppear = () => {
      rootElRef.value!.classList.remove('is-hidden')
    }

    const onResize = debounce(200, () => {
      ScrollTrigger.getById(name).kill()
      tl.kill()
      play()
    })

    onMounted(() => {
      // Wait for all imgs to be loaded before creating gallery
      const imgPromises: Promise<void>[] = []

      if (imgElsRef.value) {
        imgElsRef.value.forEach(img => {
          imgPromises.push(
            new Promise(resolve => {
              if (img.complete) {
                // if img is cached, resolve immediately
                resolve()
              } else {
                // or wait for it to load
                img.onload = () => {
                  resolve()
                }
              }
            })
          )
        })
      }

      Promise.all(imgPromises).then(() => {
        play()
        galleryElRef.value!.classList.remove('before-load')
      })

      // Position adjectives..?
      if (adjectivesElRef.value && adjectivesElRef.value.length > 0) {
        adjectivesData.value = adjectives.jobs

        const update = (el, position) => {
          const { x, y } = position

          el.style.left = `${x}%` // Some calculations based on image size…
          el.style.top = `${y}%`
        }

        adjectivesData.value.forEach((d, i) => {
          if (!adjectivesElRef.value || !adjectivesElRef.value[i]) {
            return
          }

          const { position } = d

          update(adjectivesElRef.value[i], position)

          if (d.reverse) {
            adjectivesElRef.value[i].classList.add('reverse')
          }
        })
      }
    })

    onUnmounted(() => {
      if (tl) {
        tl.kill()
      }

      if (ScrollTrigger.getById(name)) {
        ScrollTrigger.getById(name).kill()
      }
    })

    return {
      rootElRef,
      galleryElRef,
      picturesElRef,
      imgElsRef,
      items,
      toggle,
      onAppear,
      onResize,
      mini,
      adjectivesElRef,
      adjectivesData,
      defaultSets,
    }
  },
})
