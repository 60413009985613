




































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { SSingle } from '@/inc/types'

import FlexibleQuote from '@/components/flexible/Quote.vue'
import FlexibleGallery from '@/components/flexible/Gallery.vue'
import FlexibleVideo from '@/components/flexible/Video.vue'

export default defineComponent({
  name: 'expertSingle',
  components: {
    FlexibleQuote,
    FlexibleGallery,
    FlexibleVideo,
  },
  props: {
    popup: {
      type: Object as () => SSingle,
      required: false,
    },
  },
  setup(props) {
    const content = props.popup ? props.popup : useGetters(['content']).content

    return {
      content,
    }
  },
})
