












import { defineComponent } from '@vue/composition-api'
import { Icon } from '../../inc/types'

export default defineComponent({
  name: 'GIcon',
  props: {
    content: {
      type: Object as () => Icon,
      Default: {} as Icon,
    },
  },
  setup() {
    return {}
  },
})
