import Vue, { VueConstructor } from 'vue'
import { Route, RouteConfig } from 'vue-router'

// eslint-disable-next-linesrc/inc/utils/resource.ts
import { langDefault, langAvailable, segments } from '@/inc/app.config'
import Root from '@/views/Root.vue'

// Format segments to (a|b)
// eslint-disable-next-line
const formatSegment = (key: string): string =>
  Array.isArray(segments[key])
    ? `(${(segments[key] as string[]).join('|')})`
    : (segments[key] as string)

// If it exists, preserve moodboard query when navigating
const preserveMoodboardQuery = (
  to: Route,
  from: Route,
  next: (Route?) => void
) => {
  if (from.query.moodboard && !to.query.moodboard) {
    const updatedTo = {
      ...to,
      query: {
        ...to.query,
        ...from.query,
      },
    }
    next(updatedTo)
  } else {
    next()
  }
}

let routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: 'v-Home' */ '@/views/Home.vue'),
    meta: {
      ssr: true,
    },
  },
  // {
  //   path: `/:newsletter${formatSegment('newsletter')}`,
  //   name: 'HomeNewsletter',
  //   component: () =>
  //     import(/* webpackChunkName: 'v-Home' */ '@/views/Home.vue'),
  //   meta: {
  //     ssr: true,
  //   },
  // },
  // {
  //   path: `/:brochure${formatSegment('brochure')}`,
  //   name: 'HomeBrochure',
  //   component: () =>
  //     import(/* webpackChunkName: 'v-Home' */ '@/views/Home.vue'),
  //   meta: {
  //     ssr: true,
  //   },
  // },
  {
    path: `/${formatSegment('welcome')}`,
    name: 'partner-step0-welcome',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/${formatSegment('welcome')}/step:step(1)/`,
    name: 'partner-step1-welcome',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/${formatSegment('welcome')}/step:step(2)/`,
    name: 'partner-step2-welcome',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/${formatSegment('welcome')}/step:step(3)/`,
    name: 'partner-step3-welcome',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/${formatSegment('welcome')}/${formatSegment('thanks')}/`,
    name: 'partner-result-welcome',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactResult.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: '/partnerbpi',
    name: 'partner-step0-bpi',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: '/partnerbpi/step:step(1)/',
    name: 'partner-step1-bpi',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: '/partnerbpi/step:step(2)/',
    name: 'partner-step2-bpi',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: '/partnerbpi/step:step(3)/',
    name: 'partner-step3-bpi',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactPartner' */ '@/views/pages/ContactPartner.vue'
      ),
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/partnerbpi/${formatSegment('thanks')}/`,
    name: 'partner-result-bpi',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactResult.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/contact/${formatSegment('rdv')}/step:step(1)/`,
    name: 'new-step1',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactNewProject.vue'
      ),
    beforeEnter: preserveMoodboardQuery,
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/contact/${formatSegment('rdv')}/step:step(2)/`,
    name: 'new-step2',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactNewProject.vue'
      ),
    beforeEnter: preserveMoodboardQuery,
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/contact/${formatSegment('rdv')}/step:step(3)/`,
    name: 'new-step3',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactNewProject.vue'
      ),
    beforeEnter: preserveMoodboardQuery,
    meta: {
      ssr: true,
    },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: `/contact/${formatSegment('rdv')}/${formatSegment('thanks')}/`,
    name: 'rdv-result',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactResult.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/contact/project/${formatSegment('thanks')}/`,
    name: 'project-result',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactResult.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/contact/other-request/${formatSegment('thanks')}/`,
    name: 'other-result',
    component: () =>
      import(
        /* webpackChunkName: 'v-ContactNewProject' */ '@/views/pages/ContactResult.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/:endpoint${formatSegment('realizations')}/`,
    component: () =>
      import(/* webpackChunkName: 'v-Projects' */ '@/views/ProjectArchive.vue'),
    meta: {
      ssr: true,
      endpoint: 'realizations',
      key: 'projects',
    },
    children: [
      {
        path: '',
        name: 'ProjectAll',
        component: () =>
          import(
            /* webpackChunkName: 'v-Projects' */ '@/components/project/ArchiveContent.vue'
          ),
        meta: {
          endpoint: 'realizations',
          key: 'projects',
        },
      },
      {
        path: ':prefix(categorie)-:category/:subcategory?/',
        name: 'ProjectCategory',
        component: () =>
          import(
            /* webpackChunkName: 'v-Projects' */ '@/components/project/ArchiveContent.vue'
          ),
        meta: {
          endpoint: 'realizations',
          archive: true,
          key: 'projects',
          pathToRegexpOptions: { strict: true },
        },
      },
    ],
  },
  {
    path: `/:endpoint${formatSegment('realizations')}/:slug/`,
    name: 'ProjectSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-ProjectSingle' */ '@/views/ProjectSingle.vue'
      ),
    meta: {
      ssr: true,
      endpoint: 'realizations',
    },
  },
  {
    path: '/:slug(showrooms)',
    name: 'ShowroomArchive',
    component: () =>
      import(/* webpackChunkName: 'v-showroom' */ '@/views/Showrooms.vue'),
    meta: {
      ssr: true,
      endpoint: 'showrooms',
    },
  },
  {
    path: '/:slug(showrooms)/:sub',
    name: 'ShowroomSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-ShowroomSingle' */ '@/views/ShowroomSingle.vue'
      ),
    meta: {
      ssr: true,
      endpoint: 'showrooms',
    },
  },
  // Magazine: archive
  {
    path: `/:endpoint${formatSegment('magazine')}/`,
    component: () =>
      import(
        /* webpackChunkName: 'v-Magazine' */ '@/views/magazine/Archive.vue'
      ),
    meta: {
      ssr: true,
      endpoint: 'magazine',
      key: 'magazine',
    },
    children: [
      {
        path: ':category?/',
        name: 'MagazineCategory',
        component: () =>
          import(
            /* webpackChunkName: 'v-MagazineCategory' */ '@/views/magazine/Category.vue'
          ),
        meta: {
          endpoint: 'magazine',
          pathToRegexpOptions: { strict: true },
          key: 'magazine',
        },
      },
    ],
  },
  // Magazine: subcategories and articles
  {
    path: `/:endpoint${formatSegment('magazine')}/:category/:slug/:sub?/`,
    name: 'MagazineSingle',
    component: () =>
      import(/* webpackChunkName: 'v-Page' */ '@/views/magazine/Single.vue'),
    meta: {
      ssr: true,
      endpoint: 'magazine',
    },
  },
  {
    path: `/:slug${formatSegment('job')}/:sub/`,
    name: 'JobSingle',
    component: () =>
      import(/* webpackChunkName: 'v-JobSingle' */ '@/views/JobSingle.vue'),
    meta: {
      ssr: true,
      endpoint: 'job',
    },
  },
  {
    path: '/jobs/:slug/',
    name: 'ExpertSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-ExpertSingle' */ '@/views/ExpertSingle.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  // Moodboard builder - Builder
  {
    path: `/${formatSegment('moodboardBuilder')}`,
    name: 'moodboard-builder',
    component: () =>
      import(
        /* webpackChunkName: 'v-moodboard-builder' */ '@/views/MoodboardBuilder.vue'
      ),
    meta: {
      ssr: true,
      hideChrome: true,
    },
  },
  // Moodboard builder - Viewer
  {
    path: `/:endpoint${formatSegment('moodboardBuilder')}/:id`,
    name: 'moodboard-builder-preview',
    component: () =>
      import(
        /* webpackChunkName: 'v-moodboard-builder-preview' */ '@/views/MoodboardBuilderPreview.vue'
      ),
    meta: {
      ssr: true,
      hideChrome: true,
      endpoint: 'moodboard/view',
    },
  },
  // Anders wonen
  {
    path: `/:endpoint${formatSegment('andersWonen')}/`,
    name: 'AndersWonen',
    component: () =>
      import(/* webpackChunkName: 'v-AndersWonen' */ '@/views/AndersWonen.vue'),
  },
  // Anders wonen single
  {
    path: `/:endpoint${formatSegment('mood')}/:slug/`,
    name: 'AndersWonenSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-AndersWonenSingle' */ '@/views/magazine/single/Experience.vue'
      ),
    meta: {
      endpoint: 'mood',
    },
  },
  {
    path: `/:slug${formatSegment('moods')}/`,
    name: 'AndersWonenMoods',
    component: () =>
      import(
        /* webpackChunkName: 'v-AndersWonenMoods' */ '@/views/anders-wonen/Archive.vue'
      ),
  },
  {
    path: '/:slug/:sub?/',
    name: 'Page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
  {
    path: '/*',
    name: 'All',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: Root as VueConstructor<Vue>,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: initialRoutes
        .filter(r => r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
    {
      path: '*',
      name: '404',
      component: () =>
        import(/* webpackChunkName: 'v-NotFound' */ '@/views/NotFound.vue'),
      meta: {
        ssr: true,
        scrollTop: true,
      },
    },
  ]

  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export { routes }
