


















































































import type { Link } from '@/inc/types'
import type { MagazineCard } from '@/components/magazine/Card.vue'

import { defineComponent, PropType, ref } from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'

interface FlexiblePromo {
  headline: string
  title: string
  experience?: MagazineCard
  picture?: string
  link?: Link
  trackingLabel: string
  anchor?: Link
  isFullscreen: boolean
  video?: string
}

export default defineComponent({
  name: 'FlexiblePromo',
  props: {
    content: {
      type: Object as PropType<FlexiblePromo>,
      required: true,
    },
  },
  setup(props) {
    const rootElRef = ref<HTMLElement | null>(null)

    // Push event to datalayer on click
    const onLinkClick = () => {
      console.log('LINK CLICK')

      if (!rootElRef.value) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel: props.content.trackingLabel || props.content.title,
        ctaType: 'promo',
        ctaUrl: props.content.experience?.link.url || props.content.link?.url,
      }

      pushCta(layer, rootElRef.value)
    }

    return { rootElRef, onLinkClick }
  },
})
