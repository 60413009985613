import gsap from 'gsap'
import { camelToSnake, toCamel } from '@/inc/utils'

const { VUE_APP_TASK } = process.env

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GtmLayer {
  event: string
  [key: string]: string | number | string[] | undefined
}

interface BaseLayer {
  event: 'form-submit' | 'clic'
  eventLabel?: string
}

interface Form extends BaseLayer {
  eventCategory: 'Submit a form'
  eventAction: string
}

export interface GtmNewsletter extends BaseLayer {
  eventCategory: 'Submit a form'
  eventAction: 'submit'
}

export interface GtmDownload extends BaseLayer {
  eventCategory: 'Download'
  eventAction: 'Brochure'
}

export type GtmCtaType =
  | 'experience'
  | 'promo'
  | 'quote'
  | 'text_left'
  | 'preencoded_center'
  | 'preencoded_center_icon'
  | 'preencoded_left_icon'
  | 'preencoded_right'
  | 'preencoded_right_icon'
  | 'preencoded_picture'
  | 'preencoded_popup'
  | 'moodboard_builder'
  | 'journal'
  | 'header'
  | 'mega_menu'
  | 'link_text'
  | 'card_magazine'
  | 'card_mood'
  | 'global'
  | 'scroll_promo'

export interface GtmCta extends GtmLayer {
  event: 'cta_click'
  clickText: string
  ctaLabel: string // label
  ctaLocation: string // y position in page
  ctaType: GtmCtaType
  ctaUrl: string // destination url
  ctaUrlFrom: string // current url
}

// Returns a percentage corresponding to the y position of the cta
export const getCtaLocation = (el: HTMLElement): string => {
  const { scrollY } = window
  const documentHeight = document.documentElement.offsetHeight
  const elTop = el.getBoundingClientRect().top
  const elPositionY = scrollY + elTop
  const percentage = (elPositionY / documentHeight) * 100
  const location = gsap.utils.snap([0, 25, 50, 75, 100], percentage)

  return `${location}%`
}

// Adds cta location before pushing to datalayer
export const pushCta = (layer: Partial<GtmCta>, el: HTMLElement) => {
  layer.event = 'cta_click'
  layer.ctaLocation = getCtaLocation(el)
  layer.ctaUrlFrom = document.location.href
  push(layer as GtmCta)
}

export const push = (layer: GtmLayer) => {
  // Format layer in snake case
  // myKey => my_key
  const snakeLayer: GtmLayer = {
    event: layer.event,
  }

  for (const key of Object.keys(layer)) {
    let formattedKey = key

    // Avoid UA properties
    if (
      ![
        'event',
        'eventCategory',
        'eventAction',
        'eventLabel',
        'eventCallback',
      ].includes(key)
    ) {
      formattedKey = camelToSnake(key)
    }

    snakeLayer[formattedKey] = layer[key]
  }

  // DEV
  console.log('GTM_PUSH', snakeLayer)

  if (VUE_APP_TASK === 'build') {
    window.dataLayer?.push(snakeLayer)
  } else {
    console.table(snakeLayer)
  }
}

// It is more comfortable for tracking to have a string value of "empty" rather than null
// eslint-disable-next-line id-length
export const getMoodboardBuilderItemValue = (value?: string) => value ?? 'empty'

// Turn utm cookie string into camelCased key value object like so
// input: utm_campaign=moodboard-builder&utm_medium=hs&utm_content=fr&utm_source=email
// output: { utmCampaign: "moodboard-builder", utmMedium: "hs", utmContent: "fr", utmSource: "email" }
// TODO: Proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseUTMCookies = ($cookie: any) => {
  const cookiesData: Record<string, string> = {}

  // Get cookies to add in form data later
  const utmCookie = $cookie.get('utm')

  // Add utm values to cookies data
  // eg: { UtmFoo: 'bar' }
  if (utmCookie) {
    const utmCookies = utmCookie.split('&')

    utmCookies.forEach((cookie: string) => {
      const splitCookie = cookie.split('=')
      const key = toCamel(splitCookie[0])

      // eslint-disable-next-line prefer-destructuring
      cookiesData[key] = splitCookie[1]
    })
  }

  return cookiesData
}
