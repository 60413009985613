











import { defineComponent } from '@vue/composition-api'

import FlexibleQuote from '@/components/flexible/Quote.vue'
import CtaText from '@/components/cta/Text.vue'
import CtPreEncoded from '@/components/cta/PreEncoded.vue'
import CtaExperience from '@/components/cta/Experience.vue'

export default defineComponent({
  name: 'GCtas',
  components: {
    FlexibleQuote,
    CtaText,
    CtPreEncoded,
    CtaExperience,
  },
  props: {
    ctas: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
