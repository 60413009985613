var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && !Array.isArray(_vm.content))?_c('div',{ref:"rootElRef",staticClass:"flexible-promo mt-xl mb-xxl",class:[
    _vm.content.isFullscreen ? 'full' : 'wrapper',
    { 'has-experience': Boolean(_vm.content.experience) } ],attrs:{"id":_vm.content.anchor && _vm.content.anchor.label}},[_c('div',{staticClass:"flexible-promo-inner"},[_c('div',{staticClass:"flexible-promo__panel"},[_c('div',{staticClass:"flexible-promo__panel__picto"},[_vm._v("%")]),(_vm.content.headline)?_c('div',{staticClass:"flexible-promo__panel__headline"},[_vm._v(" "+_vm._s(_vm.content.headline)+" ")]):_vm._e(),_c('div',{staticClass:"flexible-promo__panel__title"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")])]),_c('div',{staticClass:"flexible-promo__picture"},[(_vm.content.experience)?_c('RouterLink',{staticClass:"flexible-promo__picture__link--card",attrs:{"to":_vm.content.experience.link.url},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}},[_c('div',{staticClass:"flexible-promo__picture__link-inner"},[_c('div',{staticClass:"flexible-promo__picture__link__label",domProps:{"innerHTML":_vm._s(_vm.content.experience.title)}}),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"flexible-promo__picture__link__icon",attrs:{"symbol":"ui-arrow-right","size":"0 0 24 25"}})])]):(_vm.content.link)?_c('GAction',{staticClass:"flexible-promo__picture__link--button",attrs:{"content":Object.assign({}, _vm.content.link,
          {label: _vm.content.link.title,
          modifiers: ['btn', 'filled'],
          icon: 'arrow'}),"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}}):_vm._e(),(_vm.content.video)?_c('div',{staticClass:"flexible-promo__picture__video"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.content.video,"type":"video/mp4"}})])]):(
          (_vm.content.experience && _vm.content.experience.picture) ||
          _vm.content.picture
        )?_c('figure',{staticClass:"flexible-promo__picture__img"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(
            _vm.content.experience ? _vm.content.experience.picture : _vm.content.picture
          ),expression:"\n            content.experience ? content.experience.picture : content.picture\n          "}]})]):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }