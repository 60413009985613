import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/inc/store'
import { FileRecord } from 'vue-file-agent'
import { File, SelectOption } from '@/inc/types'
import { StorageUtils } from '@/inc/plugins/storage'

export interface StepsState {
  first: FirstStep | null
  second: SecondStep | null
  third: ThirdStep | null
  current: number
}

export interface FirstStep {
  title: string
  value: string
  zip: string | number
}

interface SecondStepCategories {
  items: SelectOption &
    {
      sfValue: string
    }[]
  value: string
}

export interface SecondStep {
  category?: string
  categories?: SecondStepCategories
  message?: string
  files?: FileRecord[] | File[]
  attachments?: string
  selectedRealisation?: string[]
  inspirations?: string
  moodboard?: string
}

export interface ThirdStep {
  lastName?: string
  firstName?: string
  email?: string
  phone?: string
  country?: string
  address?: string
  zip?: string
  city?: string
  // Partner
  accessCode?: string
  projectName?: string
}

const state: StepsState = {
  first: null,
  second: null,
  third: null,
  current: 1,
}

const getters: GetterTree<StepsState, RootState> = {
  firstStep: (state: StepsState) => (storage: StorageUtils) =>
    state.first || JSON.parse(storage.getItem('firstStep', false) || 'null'),
  secondStep: (state: StepsState) => (storage: StorageUtils) =>
    state.second || JSON.parse(storage.getItem('secondStep', false) || 'null'),
  thirdStep: (state: StepsState) => (storage: StorageUtils) =>
    state.third || JSON.parse(storage.getItem('thirdStep', false) || 'null'),
  currentStep: (state: StepsState) => state.current,
}

const mutations: MutationTree<StepsState> = {
  FIRSTSTEP_ADD(state, payload: FirstStep) {
    state.first = payload

    if (!state.third) {
      state.current = 2
    }
  },
  FIRSTSTEP_REMOVE(state) {
    state.first = null
  },
  SECONDSTEP_ADD(state, payload: SecondStep) {
    state.second = payload
    state.current = 3
  },
  SECONDSTEP_REMOVE(state) {
    state.second = null
  },
  THIRDSTEP_ADD(state, payload: ThirdStep) {
    state.third = payload
  },
  THIRDSTEP_REMOVE(state) {
    state.third = null
  },
  CURRENTSTEP_REMOVE(state) {
    state.current = 1
  },
}

const actions: ActionTree<StepsState, RootState> = {
  addFirstStep({ commit }, { data, storage }) {
    commit('FIRSTSTEP_ADD', data)
    storage.setItem('firstStep', JSON.stringify(data))
  },
  addSecondStep({ commit }, { data, storage }) {
    commit('SECONDSTEP_ADD', data)
    storage.setItem('secondStep', JSON.stringify(data))
  },
  addThirdStep({ commit }, { data, storage }) {
    commit('THIRDSTEP_ADD', data)
    storage.setItem('thirdStep', JSON.stringify(data))
  },
  cleanSteps({ commit }, { storage }) {
    storage.removeItem('firstStep')
    commit('FIRSTSTEP_REMOVE')

    storage.removeItem('secondStep')
    commit('SECONDSTEP_REMOVE')

    storage.removeItem('thirdStep')
    commit('THIRDSTEP_REMOVE')

    commit('CURRENTSTEP_REMOVE')
  },
}

const steps: Module<StepsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default steps
