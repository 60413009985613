

























import type { Picture, Link } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface PriceDetails {
  picture: Picture
  title: string
  htmltext: string
  details: {
    price: string
    architect: string
    worker: string
    dimensions: string
  }
  link: Link
}

export default defineComponent({
  name: 'FlexiblePriceDetails',
  components: {},
  props: {
    content: {
      type: Object as PropType<PriceDetails>,
      required: true,
      default: () => ({
        picture: {
          src:
            /* eslint-disable max-len */
            // 'https://images.unsplash.com/photo-1680499661732-3cfae4690e1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            'https://images.unsplash.com/photo-1677644334825-0eb411012ac0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2143&q=80',
          /* eslint-enable max-len */
        },
        title:
          'Meuble de rangement de <em>Stéphane</em> à Woluwé Saint Lambert',
        htmltext:
          /* eslint-disable max-len */
          'Ce rangement sur mesure est conçu pour répondre aux besoins de stockage d’une grande famille, tout en offrant une solution élégante et personnalisée pour garder un espace serein et minimaliste. Camber utilise des matériaux de qualité supérieure et des finitions durables pour garantir la longévité de votre meuble',
        /* eslint-enable max-len */
        details: {
          price: '5000 euros',
          architect: 'Naomie, <em>architecte dintérieur</em>',
          // worker: '',
          // dimensions: '',
        },
        link: {
          label: 'Voir cette réalisation',
          url: '#',
        },
      }),
    },
  },

  setup() {
    const detailsIcons = {
      price: 'euro',
      architect: 'pencil-ruler',
      worker: 'hammer',
      dimensions: 'ruler',
    }

    return {
      detailsIcons,
    }
  },
})
