var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"aware",rawName:"v-aware",value:({
    appear: {
      once: true,
      threshold: [0.1, 0.5, 1],
    },
  }),expression:"{\n    appear: {\n      once: true,\n      threshold: [0.1, 0.5, 1],\n    },\n  }"}],ref:"rootElRef",staticClass:"flexible-quote wrapper bordered",on:{"appear":_vm.onAppear}},[_c('div',{staticClass:"flexible-quote-container p-xl"},[(_vm.content.showAuthor)?_c('GAuthor',{staticClass:"flexible-quote__author mb-m",attrs:{"content":_vm.content}}):_vm._e(),_c('div',{ref:"textElRef",staticClass:"flexible-quote__content quote--light",domProps:{"innerHTML":_vm._s(_vm.content.htmltext || _vm.content.title)}}),(_vm.content.link)?_c('div',{ref:"linkElRef",staticClass:"flexible-quote__link"},[_c('GAction',{attrs:{"content":{
          label: _vm.content.link.label ? _vm.content.link.label : _vm.content.link.title,
          url: _vm.content.link.url,
          target: _vm.content.link.target,
          modifiers: ['btn'],
        },"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}})],1):_vm._e(),(_vm.content.text)?_c('div',{staticClass:"flexible-quote__text small",domProps:{"innerHTML":_vm._s(_vm.content.text)}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }