var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{ref:"root",staticClass:"flexible-picture",class:_vm.content.fullWidth ? 'before-load' : 'wrapper'},[_c('div',{staticClass:"flexible-picture-inner"},[_c('GPicture',{attrs:{"content":_vm.content.picture,"full":_vm.content.fullWidth,"sets":_vm.content.fullWidth && [
          '160',
          '240',
          '320',
          '480',
          '640',
          '960',
          '1280',
          '1440',
          '1600',
          '1920',
          '2240',
          '2560',
          '2880' ]}}),(_vm.content.link)?_c('a',{staticClass:"flexible-picture__link",attrs:{"href":_vm.content.link.url,"target":_vm.content.link.target}},[_c('span',{staticClass:"flexible-picture__link__label"},[_vm._v(" "+_vm._s(_vm.content.link.label)+" ")])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }