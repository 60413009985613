var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"aware",rawName:"v-aware"}],staticClass:"flexible-experts-outer pb-l",on:{"viewport":_vm.onResize}},[(_vm.popupContent)?_c('g-popup',{ref:"popup",attrs:{"content":_vm.popupContent,"template":"expert"},on:{"popup:close":_vm.closePopup}}):_vm._e(),_c('div',{staticClass:"flexible-experts wrapper"},[_c('div',{staticClass:"flexible-experts__content"},[(_vm.content)?_c('GTitle',{staticClass:"h3",attrs:{"tag":"h2","content":_vm.content.title}}):_vm._e(),(_vm.content)?_c('GIntro',{attrs:{"content":_vm.content.htmltext}}):_vm._e()],1),_c('div',{ref:"list",staticClass:"flexible-experts__slides"},[_c('div',{staticClass:"flexible-experts__slide-outer"},_vm._l((2),function(n){return _c('div',{key:n,staticClass:"flexible-experts__slide"},[(_vm.content.experts)?_c('GPicture',{staticClass:"flexible-experts__item__picture",attrs:{"cover":true,"content":_vm.content.experts[0].video.picture,"sets":[
              '240',
              '320',
              '480',
              '640',
              '960',
              '1280',
              '1600',
              '1920',
              '2240' ]},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.content.experts[0])}}}):_vm._e()],1)}),0),_c('div',{staticClass:"flexible-experts__slide-outer"},[_vm._l((3),function(n){return _c('div',{key:n,staticClass:"flexible-experts__slide"},[(_vm.content.experts)?_c('GPicture',{staticClass:"flexible-experts__item__picture",attrs:{"cover":true,"content":_vm.content.experts[0].video.picture,"sets":[
              '240',
              '320',
              '480',
              '640',
              '960',
              '1280',
              '1600',
              '1920',
              '2240' ]},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.content.experts[0])}}}):_vm._e(),_vm._v(" Test text quote ")],1)}),_c('div',{staticClass:"flexible-experts__intro"},[(_vm.content)?_c('GTitle',{staticClass:"h3",attrs:{"tag":"h2","content":_vm.content.title}}):_vm._e(),(_vm.content)?_c('GIntro',{attrs:{"content":_vm.content.htmltext}}):_vm._e()],1)],2),_c('div',{staticClass:"flexible-experts__slide-outer"},_vm._l((2),function(n){return _c('div',{key:n,staticClass:"flexible-experts__slide"},[(_vm.content.experts)?_c('GPicture',{staticClass:"flexible-experts__item__picture",attrs:{"cover":true,"content":_vm.content.experts[0].video.picture,"sets":[
              '240',
              '320',
              '480',
              '640',
              '960',
              '1280',
              '1600',
              '1920',
              '2240' ]},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.content.experts[0])}}}):_vm._e(),_vm._v(" Test text quote ")],1)}),0),_c('div',{staticClass:"flexible-experts__slide-outer"},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"flexible-experts__slide"},[(_vm.content.experts)?_c('GPicture',{staticClass:"flexible-experts__item__picture",attrs:{"cover":true,"content":_vm.content.experts[0].video.picture,"sets":[
              '240',
              '320',
              '480',
              '640',
              '960',
              '1280',
              '1600',
              '1920',
              '2240' ]},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.content.experts[0])}}}):_vm._e(),_vm._v(" Test text quote ")],1)}),0),_c('div',{staticClass:"flexible-experts__slide-outer"},_vm._l((2),function(n){return _c('div',{key:n,staticClass:"flexible-experts__slide"},[(_vm.content.experts)?_c('GPicture',{staticClass:"flexible-experts__item__picture",attrs:{"cover":true,"content":_vm.content.experts[0].video.picture,"sets":[
              '240',
              '320',
              '480',
              '640',
              '960',
              '1280',
              '1600',
              '1920',
              '2240' ]},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.content.experts[0])}}}):_vm._e(),_vm._v(" Test text quote ")],1)}),0),_c('div',{staticClass:"flexible-experts__slide-outer"},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"flexible-experts__slide"},[(_vm.content.experts)?_c('GPicture',{staticClass:"flexible-experts__item__picture",attrs:{"cover":true,"content":_vm.content.experts[0].video.picture,"sets":[
              '240',
              '320',
              '480',
              '640',
              '960',
              '1280',
              '1600',
              '1920',
              '2240' ]},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.content.experts[0])}}}):_vm._e(),_vm._v(" Test text quote ")],1)}),0)]),_c('div',{staticClass:"flexible-experts__slides__nav"},[_c('GAction',{ref:"prevButton",staticClass:"flexible-experts__slides__nav__button",attrs:{"content":{
          label: 'Précédent',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'leftArrow',
        },"aria-label":"Précédent","disabled":_vm.index === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('GAction',{ref:"nextButton",staticClass:"flexible-experts__slides__nav__button",attrs:{"content":{
          label: 'Suivant',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'arrow',
        },"aria-label":"Suivant","disabled":!_vm.canSlide},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }