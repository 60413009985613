






































































import type { Picture, ShowroomSlider } from '@/inc/types'

import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import Slidy, { TransitionInfos } from 'epic-slidy'
import gsap from 'gsap'

export default defineComponent({
  name: 'showroomSlider',
  props: {
    content: {
      type: Object as () => ShowroomSlider,
      required: true,
    },
  },
  setup(props) {
    let slider: Slidy
    const container = ref<HTMLElement | null>(null)
    const list = ref<HTMLElement | null>(null)
    const current = ref(0)
    const currentItem = ref<HTMLElement | null>(null)
    const number = ref(1)

    // Check if item is picture
    const isPicture = (
      item: Picture | Record<string, string>
    ): item is Picture => Boolean((item as Picture).src)

    const prev = () => {
      slider.slidePrev('controls')
    }

    const next = () => {
      slider.slideNext('controls')
    }

    const onResize = () => {
      const items = list.value!.querySelectorAll('li')
      console.log(
        container.value?.clientWidth,
        items[items.length - 1].clientWidth
      )
      number.value = Math.floor(
        container.value!.clientWidth / items[items.length - 1].clientWidth
      )

      if (current.value > items.length - number.value) {
        slider.slideTo(items.length - number.value, 'controls')
      }

      if (current.value === items.length - number.value) {
        gsap.set(list.value, {
          x: container.value!.clientWidth - list.value!.scrollWidth,
        })
      } else {
        gsap.set(list.value, {
          x: currentItem.value!.clientWidth * -current.value,
        })
      }
    }

    onMounted(() => {
      const items = list.value!.querySelectorAll('li')

      currentItem.value = items[current.value]

      number.value = Math.floor(
        container.value!.clientWidth / items[items.length - 1].clientWidth
      )

      const transition = (
        currentSlide: HTMLElement,
        newSlide: HTMLElement,
        { newIndex }: TransitionInfos
      ) => {
        const tl = gsap.timeline()
        current.value = newIndex

        const { length } = props.content.pictures

        // Stops current video
        if (currentSlide.classList.contains('has-video')) {
          const videoEl = currentSlide.querySelector('video')

          videoEl?.pause()
        }

        // Play new video
        if (newSlide.classList.contains('has-video')) {
          const videoEl = newSlide.querySelector('video')

          videoEl?.play()
        }

        if (current.value === length - number.value) {
          return tl
            .add('transition')
            .to(
              list.value,
              {
                x: container.value!.clientWidth - list.value!.scrollWidth,
                duration: 0.5,
              },
              'transition'
            )
            .then()
        }

        return tl
          .add('transition')
          .to(
            list.value,
            {
              x: currentItem.value!.offsetWidth * -current.value,
              duration: 0.5,
            },
            'transition'
          )
          .then()
      }

      const shouldInteract = props.content?.pictures.length > number.value

      slider = new Slidy(list.value as HTMLElement, {
        controls: false,
        click: false,
        drag: shouldInteract,
        swipe: shouldInteract,
        loop: false,
        transition,
      })

      slider.init()
    })

    onUnmounted(() => {
      if (slider) {
        slider.destroy()
      }
    })

    return {
      container,
      list,
      onResize,
      isPicture,
      prev,
      next,
      current,
      number,
    }
  },
})
