









import { defineComponent } from '@vue/composition-api'
import { FlexibleText } from '@/inc/types'

export default defineComponent({
  name: 'FlexibleHighlighted',
  props: {
    content: {
      type: Object as () => FlexibleText,
      default: () => ({
        title: 'Titre',
        abstract: 'Lorem ipsum dolor sit amet',
        htmltext: '<p>Lorem ipsum dolor sit amet</p>',
      }),
    },
  },
})
