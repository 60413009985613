



























































































import type { Picture, Link } from '@/inc/types'
import type { MagazineCategory } from '@/components/magazine/Categories.vue'

import { defineComponent, PropType, ref } from '@vue/composition-api'
import { pushCta } from '@/inc/utils'

export interface MagazineCard {
  category?: MagazineCategory
  title?: string
  displayTitle?: string
  hasVideo?: boolean
  is360?: boolean
  link: Link
  video?: string
  picture: Picture
  verticalPicture?: Picture
  displayNew?: boolean
  corner?: {
    status: string
    statusStyle: string
  }
}

export default defineComponent({
  name: 'MagazineCard',
  props: {
    content: {
      type: Object as PropType<MagazineCard>,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const rootElRef = ref<HTMLElement | null>(null)

    const onLinkClick = () => {
      ctx.emit('click:link')

      // Track link as CTA
      if (rootElRef.value) {
        pushCta(
          {
            ctaLabel: props.content.title || props.content.displayTitle,
            clickText: props.content.link.label,
            ctaType: 'card_magazine',
            ctaUrl: props.content.link.url,
          },
          rootElRef.value
        )
      }
    }

    return { rootElRef, onLinkClick }
  },
})
