
























import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ChromeFooterCta from '@/components/chrome/footer/Cta.vue'
import ChromeFooterMenu from '@/components/chrome/footer/Menu.vue'
import ChromeFooterLinks from '@/components/chrome/footer/Links.vue'

export default defineComponent({
  name: 'ChromeFooter',
  components: {
    ChromeFooterCta,
    ChromeFooterMenu,
    ChromeFooterLinks,
  },
  setup(props, ctx) {
    const { chrome } = useGetters(['chrome'])
    const { $route } = ctx.root
    const popup = ref()
    const popupContent = ref()

    const openPopup = content => {
      popupContent.value = content
    }

    const closePopup = () => {
      popupContent.value = null
    }

    onMounted(() => {
      ctx.root.$ee.$on('popup-newsletter:open', () => {
        openPopup(chrome.value.ctas.newsletter.overlay)
      })

      if ($route.query.newsletter) {
        openPopup(chrome.value.ctas.newsletter.overlay)
      }

      if ($route.query.brochure) {
        openPopup(chrome.value.ctas.brochure.overlay)
      }
    })

    return {
      chrome,
      popup,
      openPopup,
      closePopup,
      popupContent,
    }
  },
})
